(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("powerbi-client"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["powerbi-client", "react"], factory);
	else if(typeof exports === 'object')
		exports["powerbi-client-react"] = factory(require("powerbi-client"), require("react"));
	else
		root["powerbi-client-react"] = factory(root["powerbi-client"], root["react"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 